var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        !_setup.isAddingMasterMode && !_setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Details van adres")])
          : _setup.isAddingMasterMode
          ? _c("h2", [_vm._v("Toevoegen van adres")])
          : _setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Toevoegen van adresversie")])
          : _vm._e(),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _setup.hasDetails
                        ? _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm._v(" Tijdlijn: "),
                          ])
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                group: "",
                                mandatory: "",
                                color: "blue accent-3",
                              },
                              model: {
                                value: _setup.selectedTabIndex,
                                callback: function ($$v) {
                                  _setup.selectedTabIndex = $$v
                                },
                                expression: "selectedTabIndex",
                              },
                            },
                            _vm._l(_setup.detailTabs(), function (item, index) {
                              return _c(
                                "v-btn",
                                {
                                  key: index,
                                  staticClass: "v-application elevation-2",
                                  attrs: {
                                    value: index,
                                    disabled: !_setup.isReadOnly,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                _setup.ValidationObserver,
                { ref: "obs" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Geldigheid")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig vanaf",
                                          rules: "date_between|required",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig vanaf",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                    rules: "required",
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valFrom",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig tot",
                                          rules: `date_between|date_after:${_setup.selectedData.valFrom}`,
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig tot",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valTill,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valTill",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valTill",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: {
                                noBorders:
                                  !_setup.isEditDataMode &&
                                  !_setup.isEditTLMode,
                              },
                            },
                            [
                              _c("header", [_vm._v("Aanpassing")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aanpassings omschrijving",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Omschrijving",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.allowEdit ||
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .modDescr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "modDescr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.modDescr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Door",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.selectedData.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "selectedData.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.modDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modDateFormatted = $$v
                                              },
                                              expression: "modDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "masonry",
                        {
                          attrs: {
                            cols: { default: 3, 768: 2, 1264: 1 },
                            gutter: 12,
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Locatie definitie")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Referentie",
                                          rules: "required|max:50",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Referentie",
                                                    counter: 50,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.ref,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "ref",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.ref",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Omschrijving",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.descr,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "descr",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.descr",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Type",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Type",
                                                    items:
                                                      _setup.addressableObjectTypes,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.typ,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "typ",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.typ",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "SubTypeHor",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Sub type",
                                                    items:
                                                      _setup.addressableObjectSubTypesHor,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .subTypHor,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "subTypHor",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.subTypHor",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "SubTypeVer",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Etage",
                                                    items:
                                                      _setup.addressableObjectSubTypesVer,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .subTypVer,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "subTypVer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.subTypVer",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "RoofType",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Dak type",
                                                    items:
                                                      _setup.addressableObjectRoofTypes,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .roofTyp,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "roofTyp",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.roofTyp",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Adres")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "AddressType",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Adres type",
                                                    items: _setup.addressTypes,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .adrTyp,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "adrTyp",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.adrTyp",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Straatnaam",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.street,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "street",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.street",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: { name: "Huisnummer" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Huisnummer",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.hn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "hn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.hn",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Huisletter",
                                          rules: "max:1",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Huisletter",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.hl,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "hl",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.hl",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Toevoeging",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.hna,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "hna",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.hna",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Postcode",
                                          rules: "postalcode",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Postcode",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.pc,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "pc",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.pc",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Woonplaats",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.city,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "city",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.city",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Land",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.country,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "country",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.country",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Positie")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("location-map", {
                                        attrs: { location: _setup.coordinates },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Breedtegraad",
                                          rules: "double|between:-180,180",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Breedtegraad (lat)",
                                                    value:
                                                      _setup.selectedData.lat,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.lat =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Lengtegraad",
                                          rules: "double|between:-180,180",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Lengtegraad (lon)",
                                                    value:
                                                      _setup.selectedData.lon,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.lon =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Herkomst")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Bron",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Bron",
                                                    items:
                                                      _setup.addressSourceTypes,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .srcTyp,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "srcTyp",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.srcTyp",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_setup.sourceIsBag,
                                          expression: "!sourceIsBag",
                                        },
                                      ],
                                    },
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Bron referentie",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.srcRef,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "srcRef",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.srcRef",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _setup.sourceIsBag,
                                          expression: "sourceIsBag",
                                        },
                                      ],
                                    },
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "BAG-id",
                                          rules: `${
                                            _setup.sourceIsBag
                                              ? "numeric|min:15|max:16"
                                              : ""
                                          }`,
                                          mode: "lazy",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "BAG-id",
                                                    counter: 18,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .srcRef,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "srcRef",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.srcRef",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Prijzen")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs elektriciteit",
                                          rules:
                                            "double|decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Elektriciteit",
                                                      value:
                                                        _setup.selectedData
                                                          .priceElectricity,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceElectricity =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/kWh")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs gas",
                                          rules:
                                            "double|decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Gas",
                                                      value:
                                                        _setup.selectedData
                                                          .priceGas,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceGas =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/Nm³")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs biomassa",
                                          rules:
                                            "double|decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Biomassa",
                                                      value:
                                                        _setup.selectedData
                                                          .priceBiomass,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceBiomass =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/GJ")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs warmte",
                                          rules:
                                            "double|decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Warmte",
                                                      value:
                                                        _setup.selectedData
                                                          .priceHeat,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceHeat =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/GJ")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Prijs koude",
                                          rules:
                                            "double|decimal:4|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Koude",
                                                      value:
                                                        _setup.selectedData
                                                          .priceCold,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.priceCold =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("€/GJ")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Gebruik")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Werkruimte",
                                          rules:
                                            "double|decimal:2|between:0,99999999999999.9999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      name: "Werkruimte",
                                                      label: "Werkruimte",
                                                      value:
                                                        _setup.selectedData
                                                          .workspace,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.workspace =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("m²")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "FTE",
                                          rules:
                                            "double|decimal:1|between:0,99999999.9",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "FTE",
                                                    value:
                                                      _setup.selectedData.fte,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.fte =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Bezettingsgraad",
                                          rules:
                                            "double|between:0,100.00|decimal:2",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Bezettingsgraad",
                                                      value:
                                                        _setup.selectedData
                                                          .occupancyRate,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.occupancyRate =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("%")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Verhuurgraad",
                                          rules:
                                            "double|between:0,100.00|decimal:2",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Verhuurgraad",
                                                      value:
                                                        _setup.selectedData
                                                          .rentalRate,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.rentalRate =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("%")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Gebruiksuren",
                                          rules:
                                            "double|between:0,9999999.99|decimal:2",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      "hide-details": "auto",
                                                      label: "Gebruiksuren",
                                                      value:
                                                        _setup.selectedData
                                                          .hourOfUse,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.hourOfUse =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("uren/week")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Gebruikstijd",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.usageTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "usageTime",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.usageTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Extra")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          label: "Planningshorizon",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.planningHorizon,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "planningHorizon",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.planningHorizon",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Datascore",
                                          rules:
                                            "double|between:0,9999999.99|decimal:2",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    label: "Datascore",
                                                    value:
                                                      _setup.selectedData
                                                        .dataScore,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.dataScore =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.addModus
                        ? _c(
                            "masonry",
                            { attrs: { cols: { default: 1 }, gutter: 12 } },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed" },
                                [
                                  _c("header", [
                                    _vm._v(
                                      "Gegevens overnemen van sub locatie"
                                    ),
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-data-table", {
                                            staticClass: "bag",
                                            attrs: {
                                              dense: "",
                                              "disable-sort": "",
                                              "disable-pagination": "",
                                              headers: _setup.subHeaders,
                                              "hide-default-footer": "",
                                              items: _setup.subLocations,
                                              "item-key": "detailId",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: `item.actions`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                            plain: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _setup.onSelectSubLocation(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-forward "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _setup.subLocationSelected,
                                              callback: function ($$v) {
                                                _setup.subLocationSelected = $$v
                                              },
                                              expression: "subLocationSelected",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("details-buttons", {
                        attrs: {
                          addMode: _setup.isAddingMode,
                          editMode: !_setup.isReadOnly,
                          allowSave: !_setup.isSaving,
                        },
                        on: {
                          edit: _setup.setEditMode,
                          "edit-tl": _setup.setEditTLMode,
                          "on-cancel": _setup.onCancel,
                          "add-new-detail": _setup.addNewDetail,
                          "on-cancel-add": function ($event) {
                            return _setup.onCancelAdd()
                          },
                          "save-detail": _setup.saveDetail,
                          "save-new-detail": _setup.saveNewDetail,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }